
       if (typeof window !== 'undefined') {
         function loadSvg() {
           var body = document.body;
           var svgDom = document.getElementById('__svg__icons__dom__');
           if(!svgDom) {
             svgDom = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
             svgDom.style.position = 'absolute';
             svgDom.style.width = '0';
             svgDom.style.height = '0';
             svgDom.id = '__svg__icons__dom__';
             svgDom.setAttribute('xmlns','http://www.w3.org/2000/svg');
             svgDom.setAttribute('xmlns:link','http://www.w3.org/1999/xlink');
           }
           svgDom.innerHTML = "<symbol  fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\" class=\"prefix__feather prefix__feather-chevron-down\" viewBox=\"0 0 24 24\" id=\"icon-chevron-down\"><path d=\"m6 9 6 6 6-6\" /></symbol><symbol  viewBox=\"0 0 6.434 10.747\" id=\"icon-chevron-left\"><path d=\"M5.373 10.747 0 5.374 5.373.001l1.061 1.061-4.312 4.313 4.313 4.313Z\" fill=\"currentColor\" /></symbol><symbol  fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\" class=\"prefix__feather prefix__feather-chevron-up\" viewBox=\"0 0 24 24\" id=\"icon-chevron-up\"><path d=\"m18 15-6-6-6 6\" /></symbol><symbol fill=\"none\"  viewBox=\"0 0 22 21\" id=\"icon-download\"><path d=\"M20.95 13.078a.781.781 0 0 0-.782.781v2.668a2.371 2.371 0 0 1-2.371 2.371H4.203a2.371 2.371 0 0 1-2.371-2.37v-2.669a.782.782 0 0 0-1.562 0v2.668a3.938 3.938 0 0 0 3.933 3.934h13.594a3.938 3.938 0 0 0 3.933-3.934V13.86a.781.781 0 0 0-.78-.78Z\" fill=\"currentColor\" /><path d=\"M10.445 15.36a.782.782 0 0 0 1.11 0L16 10.913a.781.781 0 0 0-1.094-1.105l-3.125 3.125V1.32a.781.781 0 1 0-1.562 0v11.602L7.094 9.797a.782.782 0 0 0-1.106 1.105l4.457 4.457Z\" fill=\"currentColor\" /></symbol><symbol  fill=\"currentColor\" class=\"prefix__bi prefix__bi-image-fill\" viewBox=\"0 0 16 16\" id=\"icon-image-fill\"><path d=\"M.002 3a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-12a2 2 0 0 1-2-2V3zm1 9v1a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V9.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12zm5-6.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0z\" /></symbol><symbol  fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\" class=\"prefix__feather prefix__feather-x\" viewBox=\"0 0 24 24\" id=\"icon-x\"><path d=\"M18 6 6 18M6 6l12 12\" /></symbol>";
           body.insertBefore(svgDom, body.lastChild);
         }
         if(document.readyState === 'loading') {
           document.addEventListener('DOMContentLoaded', loadSvg);
         } else {
           loadSvg()
         }
      }
        
export default {}